import React from 'react'

import { Link, graphql } from 'gatsby'
import { Layout } from '../components/layout.jsx'
const _ = require('lodash')

export default class Tags extends React.Component {
  render () {
    const { tag } = this.props.pageContext
    const { edges, totalCount } = this.props.data.allMarkdownRemark
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with "${_.startCase(tag)}"`

    return (
      <Layout location={this.props.data.location} title={this.props.data.site.siteMetadata.title}>
        <h1>{tagHeader}</h1>
        <ul>
          {edges.map(({ node }) => {
            return (
              <li key={node.fields.slug}>
                <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
              </li>
            )
          })}
        </ul>
        {/*
                This links to a page that does not yet exist.
                We'll come back to it!
              */}
        <Link to='/tag'>All tags</Link>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
      ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
